.contact_details {
    display: grid;
    row-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    margin: auto;
    width: 50%;
    border: 1px solid #ddd;
    background-color: #1e1e22;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-bottom: 40px;
    color: #fcfcfc;
    box-shadow: 0 0 8px rgba(255, 0, 0, 0.3); 
  }
  
  
  .head h3 {
    font-size: 32px;
    margin-bottom: 15px;
    color: #f1f1f1;
    text-shadow: 6px 2px 4px #ff0000; 
  }
  
  .head p {
    font-size: 16px;
    color: #bbb;
  }
  
  .names {
    display: grid;
    grid-template-columns:repeat(2,1fr);
    gap: 20px;
  }
  
  .names div {
    border: 1px solid #333;
    background-color: #2a2a2f;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s, background-color 0.3s;
  }
  
  
  .names div p {
    margin: 5px 0;
    color: #fff;
  }
  
  .names div p a {
    color:#ffcccc;
    text-decoration: none;
    font-weight: bold;
  }
  
  .names div p a:hover {
    text-decoration: underline;
  }
  
  @media ( max-width:680px ) {
    .contact_details{
      width:80%;
      
    }
    .names{
        grid-template-columns: 1fr;
    }
    
  }
  @media(max-width:1200px)
  {
    .contact_details{
        width: 80%;
    }
  }

  .names div:hover {
    background-color: #333;
    transform: translateY(-2px);
  }

  .names div p a:hover {
    text-decoration: underline;
  }
 