.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding-top: 75px;
    background-color: #000000;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}

.login-box {
    background: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    border-radius: 15px;
    width: 360px;
    box-shadow: 0px 4px 15px rgba(169, 169, 169, 0.6);
    border: 1px solid rgba(169, 169, 169, 0.6);

}

.login-toggle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.login-toggle button {
    flex: 1;
    padding: 0.5rem 0;
    text-align: center;
    color: rgba(255, 255, 255, 0.833);
    background: none;
    border: none;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    font-size: 1rem;
}

.login-toggle button.active-tab {
    color: #de0038;
    border-color: #800020;
    transition: color 0.3s ease, border-color 0.3s ease;
}

.login-toggle button:hover {
    color: #800020;
    transition: color 0.3s ease;
}

.login-title {
    font-size: 1.8rem;
    text-align: center;
    color: #fff;
    margin-bottom: 1.5rem;
    font-weight: bold;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.login-input {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    border-radius: 8px;
    font-size: 1rem;
}

.login-input:focus {
    outline: none;
    border-color: #800020;
    background-color: rgba(255, 255, 255, 0.2);
}

.password-field {
    position: relative;
}

.show-password {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
}

.password-field {
    position: relative;
}

.password-field .field-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 18px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.6);
    transition: color 0.3s ease;
}

.password-field .field-icon:hover {
    color: #de0038;
}

.password-field input {
    width: 100%;
    padding-right: 40px;
}

.login-button {
    width: 100%;
    padding: 0.8rem;
    background-color: #800020;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #a51c34;
}

.forgot-password {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.6);
}

.forgot-link {
    color: #de0038;
    text-decoration: none;
}

.forgot-link:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {

    .login-box {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
        border-radius: 10px;
        box-shadow: 0px 4px 10px rgba(169, 169, 169, 0.6);
    }

}

@media (min-width: 768px) and (max-width: 1024px) {

    .login-box {
        width: 360px;
        max-width: 480px;
        padding: 1.5rem;
        border-radius: 12px;
        box-shadow: 0px 4px 12px rgba(169, 169, 169, 0.7);
    }

    .login-title {
        font-size: 1.6rem;
    }

    .login-input {
        font-size: 0.9rem;
        padding: 0.7rem;
    }

    .login-button {
        font-size: 0.95rem;
        padding: 0.7rem;
    }

    .forgot-password {
        font-size: 0.85rem;
    }
}
