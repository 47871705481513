.main .heading {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 20px;
  overflow-y: hidden;
}

.main {
  position: relative;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0;
}

.main .initial {
  display: flex;
  height: 100vh;
  overflow-y: hidden;
  padding: 20px;
  padding-left: 40px;
  align-items: center;
  justify-content: center;
  color: white;
}

.border1 {
  position: absolute;
  bottom: 00px;
  left: 0px;
}
@keyframes rotate-dots {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.home_bgcircle1__MiYGt {
  animation: rotate-dots 2.66s linear infinite;
  background-image: url("../assets/images/img\ red.png");
  background-position: 50%;
  background-size: contain;
  border-radius: 50%;
  height: 550px;
  position: absolute;
  width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main .initial div {
  flex: 1;
  overflow-y: hidden;
}

.main .initial .div2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
}

.button-container {
  padding: 2px;
}
.div2 .logo {
  width: 280px;
  height: 280px;

  z-index: 10;
}

.div1 {
  background-image: url("../assets/images/red_neurons.png");
  display: flex;
  height: inherit;
  justify-content: center;
  align-items: center;
}

.initial .theme {
  color: white;
  font-size: 26px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
}

.button-container {
  padding: 15px;
}

.glow-button {

  cursor: pointer;
  background: linear-gradient(135deg, #6a5acd, #8a2be2);
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  padding: 10px 25px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 0 10px rgba(138, 43, 226, 0.6);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  transition: all 0.1s ease-in-out;
}

.glow-button:hover {
  display: block;
  filter: drop-shadow(0 0 5px #fffdef);
}

@keyframes ohyeah {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.new {
  display: flex;
  flex-direction: column;
  height: 80%;
}

.initial .des-1 {
  position: absolute;
  right: 0px;
  width: 50em;
  height: 30em;
  background-color: #f0f0f0;
}

.initial .des-2 {
  position: absolute;
  right: 10px;
  top: 9px;
  width: 14em;
  height: 15em;
  transform: rotate(180deg);
}
.pres {
  font-size: 20px;
  font-weight: 300;
}
.h2 {
  padding-top: 20px;
  background-color: black;
  color: white;
  font-size: 40px;
}

.about {
  background-color: black;
  padding: 20px;
  padding-left: 40px;
  display: flex;
  color: white;
}
.about .first {
  display: flex;
  text-align: left;
}

.about .second {
  padding: 10px;
  color: white;
  font-size: 20px;
  line-height: 40px;
  padding-top: 50px;
  padding-right: 50px;
}

.about .second::before {
  content: "";
  position: absolute;

  background: rgba(0, 0, 0, 0.1);
}

.about div {
  flex: 1;
}
.second button {
  margin-top: 20px;
  background: linear-gradient(135deg, #e15d5d, #b22e2e);
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  padding: 10px 25px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 0 10px rgba(138, 43, 226, 0.6);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  transition: all 0.1s ease-in-out;
}



.main .border {
  height: 250px;
  width: 1500px;
}

@media (max-width: 1050px) {
  .main .heading {
    margin-top: 80px;
  }
  .main .initial {
    height: auto;
    flex-direction: column;
  }
  .about {
    height: auto;
    flex-direction: column;
  }
  .theme {
    margin-top: 20px;
  }
}

@media (max-width: 594px) {
  .button-container {
    margin-bottom: 40px;
  }
  .home_bgcircle1__MiYGt {
    visibility: visible;
    animation: rotate-dots 2.66s linear infinite;
    background-image: url("../assets/images/img\ red.png");
    background-position: 50%;
    background-size: contain;
    border-radius: 50%;
    height: 500px;
    position: absolute;
    width: 500px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main .initial .logo {
    height: 15em;
    width: 15em;
    visibility: visible;
  }
  .h2 {
    font-size: 35px;
  }
  .main .initial .contact_but {
    background-color: black;
    color: #f0f0f0;
    font-size: 13px;
    cursor: pointer;
    border: 1px solid black;
  }
  .initial .theme {
    color: rgb(255, 255, 255);
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    padding: 10px;
    margin-top: 20px;
  }

  
  .button {
    margin-bottom: 20px;
  }

  .about p {
    font-size: 14px;
    line-height: 25px;
  }
  .about {
    padding-top: 0;
    padding-left: 20px;
  }
  .main h2 {
    margin-bottom: 0;
  }
  .about .second {
    padding-right: 0;
  }
  .about .second {
    padding-left: 0;
  }
 
  .theme {
    z-index: 10;
  }
}

@media (max-width: 522px) {
  .main .initial .heading {
    font-size: 30px;
  }

  .home_bgcircle1__MiYGt {
    visibility: visible;
    animation: rotate-dots 2.66s linear infinite;
    background-image: url("../assets/images/img\ red.png");
    background-position: 50%;
    background-size: contain;
    border-radius: 50%;
    height: 470px;
    position: absolute;
    top: 14%;
    width: 470px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main .initial .contact_but {
    padding: 10px;
    background-color: black;
    color: #f0f0f0;
    font-size: 13px;
    cursor: pointer;
    border: 1px solid black;
  }
  .initial .theme {
    padding: 10px;
    color: rgb(255, 255, 255);
    font-size: 20px;
    border: 1px solid black;
    z-index: 10;
  }

  .button {
    display: flex;
    margin-bottom: 20px;
  }
}

@media(max-width:514px)
{

  .home_bgcircle1__MiYGt {
    visibility: visible;
    animation: rotate-dots 2.66s linear infinite;
    background-image: url("../assets/images/img\ red.png");
    background-position: 50%;
    background-size: contain;
    border-radius: 50%;
    height: 460px;
    position: absolute;
    width: 460px;
    top:16%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .home_bgcircle1__MiYGt {
    visibility: visible;
    animation: rotate-dots 2.66s linear infinite;
    background-image: url("../assets/images/img\ red.png");
    background-position: 50%;
    background-size: contain;
    border-radius: 50%;
    height: 460px;
    position: absolute;
    width: 460px;
    top:17%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {


  .logo {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .home_bgcircle1__MiYGt {
    visibility: visible;
    animation: rotate-dots 2.66s linear infinite;
    background-image: url("../assets/images/img\ red.png");
    background-position: 50%;
    background-size: contain;
    border-radius: 50%;
    height: 460px;
    position: absolute;
    width: 460px;
    top:19%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


@media (max-width: 472px) {
  .home_bgcircle1__MiYGt {
    visibility: visible;
    animation: rotate-dots 2.66s linear infinite;
    background-image: url("../assets/images/img\ red.png");
    background-position: 50%;
    background-size: contain;
    border-radius: 50%;
    height: 460px;
    position: absolute;
    width: 460px;
    top:17%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 422px) {
  .home_bgcircle1__MiYGt {
    visibility: visible;
    animation: rotate-dots 2.66s linear infinite;
    background-image: url("../assets/images/img\ red.png");
    background-position: 50%;
    background-size: contain;
    border-radius: 50%;
    height: 460px;
    position: absolute;
    width: 460px;
    top:19%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 357px) {
  .home_bgcircle1__MiYGt {
    visibility: visible;
    animation: rotate-dots 2.66s linear infinite;
    background-image: url("../assets/images/img\ red.png");
    background-position: 50%;
    background-size: contain;
    border-radius: 50%;
    height: 465px;
    position: absolute;
    width: 465x;
    top:22%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width:345px) {
  .home_bgcircle1__MiYGt {
    visibility: visible;
    animation: rotate-dots 2.66s linear infinite;
    background-image: url("../assets/images/img\ red.png");
    background-position: 50%;
    background-size: contain;
    border-radius: 50%;
    height: 440px;
    position: absolute;
    width: 440px;
    top:17%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
}

@media (max-width: 350px) {
  .home_bgcircle1__MiYGt {
    visibility: visible;
    animation: rotate-dots 2.66s linear infinite;
    background-image: url("../assets/images/img\ red.png");
    background-position: 50%;
    background-size: contain;
    border-radius: 50%;
    height: 460px;
    position: absolute;
    width: 460px;
    top:22%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}