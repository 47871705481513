.footer {
    background-color: #000;
    color: #fff;
    padding: 2rem 1rem;
    font-family: Arial, sans-serif;
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; 
    gap: 2rem; 
    margin-bottom: 2rem;
}

.footer-heading {
    font-size: 2rem;
    color: #dcdcdc;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: bold;
    width: 100%; 
}

.footer-combined-column {
    display: flex;
    flex-direction: column; 
    gap: 1.5rem;
    flex: 1; 
    max-width: 400px; 
}

.footer-column {
    flex: 1;
    background-color: #2a2a2d;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    overflow: visible;
}

.footer-container h1,h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #dcdcdc;
}

.footer-container .footer-combined-column .footer-column h1,h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #dcdcdc;
}

.map {
    width: 100%;
    height: 200px;
    border: none;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
}

p {
    margin: 0.5rem 0;
    line-height: 1.5;
    color: #aaa;
}

.social-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

.social-icons a {
    color: #ac0606;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #fff;
}

.contact-card {
    background-color: #3a3a3d;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    text-align: left;
    color: #ccc;
}

.query-form input,
.query-form textarea {
    width: 100%;
    padding: 0.8rem;
    margin-bottom: 2rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #2a2a2d;
    color: #fff;
}

.query-form button {
    width: 100%;
    padding: 0.8rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #650707;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.query-form button:hover {
    background-color: #810d0d;
}

.footer-bottom {
    text-align: center;
    margin-bottom: 1rem;
    color: #aaa;
}

.footer-bottom a {
    display: inline-block;
    color: #e50707;
    text-decoration: none;
    margin: 0 0.5rem;
    transition: color 0.3s;
}

.footer-bottom a:hover {
    color: #fff;
}

.footer-line {
    text-align: center;
    font-size: 0.8rem;
    color: #555;
    margin-top: 1rem;
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column; 
        align-items: center;
    }

    .footer-column,
    .footer-combined-column {
        max-width: 100%;
    }
}