body {
    font-family: "Arial", sans-serif;
    background-color: #000000;
    margin: 0;
    padding: 0;
    color: #fff;
}

.user-profile-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    
    padding: 80px 20px 40px 20px;
}

.user-card {
    background: linear-gradient(to bottom, #800020, #301515);
    padding: 25px 35px;
    border-radius: 12px;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.6);
    width: 600px;
    text-align: center;
    margin-top: 35px;
}

.user-header {
    margin-bottom: 25px;
}

.user-header h2 {
    color: #f8f8f8;
    font-size: 1.8rem;
    margin: 0 0 10px 0;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);
}

.user-header p {
    font-size: 1rem;
    margin: 5px 0;
    color: #ccc;
}

.user-content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
}

.user-section {
    flex: 1;
    background: #292929;
    padding: 15px;
    border-radius: 8px;
}

.user-section h3 {
    font-size: 1.2rem;
    color: #f8f8f8;
    border-bottom: 2px solid #f8f8f8;
    margin-bottom: 20px;
    padding-bottom: 8px;
}

.user-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.user-section li {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #bbb;
    padding: 8px 12px;
    border-radius: 6px;
    background: #1e1e1e;
    transition: background 0.3s ease, color 0.3s ease;
}

.user-section li:hover {
    background: #800020;
    color: #fff;
}
