.loader_wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000000;
}

.loader_wrapper p {
    color: rgba(255, 255, 255, 0.75);
    font-size: 2.5rem;
}

.spinner_box {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.leo_border_1 {
    position: absolute;
    width: 150px;
    height: 150px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(63, 249, 220);
    background: linear-gradient(0deg, rgba(63, 249, 220, 0.1) 33%, rgba(63, 249, 220, 1) 100%);
    animation: spin3D 1.8s linear 0s infinite;
}

.leo_core_1 {
    width: 100%;
    height: 100%;
    background-color: #37474faa;
    border-radius: 50%;
}

.leo_border_2 {
    position: absolute;
    width: 150px;
    height: 150px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(251, 91, 83);
    background: linear-gradient(0deg, rgba(251, 91, 83, 0.1) 33%, rgba(251, 91, 83, 1) 100%);
    animation: spin3D 2.2s linear 0s infinite;
}

.leo_core_2 {
    width: 100%;
    height: 100%;
    background-color: #1d2630aa;
    border-radius: 50%;
}

@keyframes spin3D {
    from {
        transform: rotate3d(.5, .5, .5, 360deg);
    }

    to {
        transform: rotate3d(0deg);
    }
}