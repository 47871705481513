.abt-no-select {
    user-select: none; /* Prevents text selection */
    -webkit-user-select: none; /* For Safari */
    -moz-user-select: none; /* For Firefox */
    -ms-user-select: none; /* For IE/Edge */
}

.abt-container {
    max-width: 1200px;
    margin: auto;
    padding: 40px 20px;
}

.abt-title {
    margin-top: 20px;
    font-size: 3rem;
    font-weight: bold;
    color: #fcfcfc;
    text-align: center;
    padding: 40px;
    text-shadow: 6px 2px 4px #ff0000;
}

.abt-section {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid rgb(135, 1, 1);
    background-color: black;
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    transition: box-shadow 0.2s ease-in-out;
}

/* Glowing effect on hover */
.abt-section:hover {
    box-shadow: 0 0 15px 5px rgba(210, 0, 0, 0.8);
}

/* Layout for right and left sections */
.abt-section.abt-right {
    flex-direction: row;
}

.abt-section.abt-left {
    flex-direction: row-reverse !;
}

/* Styled h2 */
.abt-content h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #c20024;
    text-transform: uppercase;
}

/* Increased font size for paragraph */
.abt-content p {
    font-size: 1.2rem;
    color: #d3d3d3;
    line-height: 1.6;
    text-align: justify;
}

/* Text content */
.abt-content {
    flex: 2;
}

/* Image styles */
.abt-image {
    width: 37%;
    border-radius: 10px;
}

/* Buttons */
.abt-button,
.abt-buttons {
    background-color: #808080;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    margin-top: 10px;
    transition: background 0.3s ease;
}

.abt-button:hover,
.abt-buttons:hover {
    background-color: #800020;
}

/* Responsive Design */
@media (max-width: 768px) {
    .abt-section {
        flex-direction: column-reverse !important; /* Image on top */
        text-align: center;
        border-width: 1px;
        padding: 20px;
    }

    .abt-image {
        width: 70%;
        max-width: 300px;
    }

    .abt-content {
        width: 100%;
    }

    .abt-content h2 {
        font-size: 1.8rem;
    }

    .abt-content p {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .abt-section {
        display: flex;
        flex-direction: column;
        text-align: center;
        border-width: 1px;
        padding: 20px;
    }

    #reach {
        flex-direction: column !important;
    }

    #reach img {
        order: -1;
    }

    .abt-image {
        width: 70%;
        max-width: 300px;
    }

    .abt-content {
        width: 100%;
    }

    .abt-content h2 {
        font-size: 1.8rem;
    }

    .abt-content p {
        font-size: 1rem;
    }
}
