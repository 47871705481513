@tailwind base;
@tailwind components;
@tailwind utilities;
/* Override default react-hot-toast styles */
.react-hot-toast .toast {
    display: flex;
    align-items: center;
    max-height: 80px;
    padding: 12px;
    border-radius: 8px;
    font-weight: medium;
    font-size: 0.875rem; /* Tailwind text-sm */
    line-height: 1.2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  /* Success Toast */
  .react-hot-toast .toast-success {
    background-color: #38a169; /* Tailwind bg-green-500 */
    color: #ffffff;
  }
  
  /* Error Toast */
  .react-hot-toast .toast-error {
    background-color: #e53e3e; /* Tailwind bg-red-500 */
    color: #ffffff;
  }
  
  /* Default (fallback) toast color */
  .react-hot-toast .toast {
    background-color: #2d3748; /* Tailwind bg-gray-800 */
    color: #ffffff;
  }
  
  /* Icon Styling */
  .react-hot-toast .toast-icon {
    min-height: 24px;
    max-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
/* Scrollbar for Webkit browsers (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, rgb(88, 0, 0), white);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, white, rgb(88, 0, 0));
}

@supports (scrollbar-width: thin) {
  * {
    scrollbar-width: thin;
    scrollbar-color: white black;
  }
}
